import React, { useState } from "react";
import { Card, CardContent, Divider, Pagination, Typography } from "@mui/material";

import GlyphString from "./glyph_string";

const ParallelText = props => {
    const numPages = Math.ceil(Object.keys(props.matches).length / 5);
    const [start, setStart] = useState(0);

    const handleClick = value => {
        setStart((value - 1) * 5);
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Tablet {props.tablet}
                </Typography>
                {Object.keys(props.matches).slice(start, start + 5).map(item => (
                    <div key={item}>
                        <Typography>
                            {item.split(" ")[0]}
                        </Typography>
                        <GlyphString string={props.matches[item][0] + " " + props.matches[item][1] + " " + props.matches[item][2]} glyphSize={32} />
                        <Typography>
                            {props.matches[item][0]} <strong>{props.matches[item][1]}</strong> {props.matches[item][2]}
                        </Typography>
                        <Divider />
                    </div>
                ))}
                <Pagination
                    size="small"
                    count={numPages}
                    sx={{ mt: 1 }}
                    onChange={(e, value) => handleClick(value)}
                />
            </CardContent>
        </Card>
    );
}

export default ParallelText;