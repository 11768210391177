import { Box, Chip } from "@mui/material";

const TabletSelection = props => {
    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", listStyle: "none" }}>
            {props.tablets.map(tablet => (
                <Chip
                    label={tablet}
                    color={(props.selectedTablets.includes(tablet) && "success") || "default"}
                    sx={{ m: 0.5, minWidth: {xs: "36px", sm: "48px", md: "72px"} }}
                    onClick={() => props.handleClick(tablet)}
                />
            ))}
        </Box>
    );
}

export default TabletSelection;