import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Slider,
    Switch,
    Typography
} from "@mui/material";

import { useHttpClient } from "../hooks/http-hook";
import TABLETS from "../components/tablets";
import TabletSelection from "../components/tablet_selection";
import CompareList from "../components/compare_list";

const Compare = props => {
    const [searched, setSearched] = useState(false);
    const [searching, setSearching] = useState(false);
    const [tablets, setTablets] = useState([]);
    const [option, setOption] = useState("full");
    const [exact, setExact] = useState(false);
    const [matches, setMatches] = useState([]);
    const [size, setSize] = useState(0);
    const [matchLength, setMatchLength] = useState(6);

    const [valid, setValid] = useState(false);

    const { sendRequest } = useHttpClient();

    const handleSubmit = async () => {
        setSearched(true);
        setMatches([]);
        setSearching(true);

        const url = new URL("https://api.rongoscript.org/compare");
        // const url = new URL("http://localhost:8080/compare");
        url.searchParams.append("tablets", tablets);
        url.searchParams.append("option", option);
        url.searchParams.append("exact", exact);
        url.searchParams.append("matchLength", matchLength);

        const responseData = await sendRequest(
            url.toString(),
            "GET",
            null,
            {
                "Content-Type": "application/json",
                "api-key": process.env.REACT_APP_API_KEY
            }
        )

        setMatches(responseData.matches);
        setSize(responseData.size);
        setSearching(false);
    }

    const validateSearch = () => {
        setValid(tablets.length === 2);
    }

    useEffect(() => {
        validateSearch();
    }, [tablets]);

    const checkboxHandler = letter => {
        if (tablets.includes(letter)) {
            const new_tablets = tablets.filter(item => item !== letter);
            setTablets(new_tablets);
        } else if (tablets.length < 2) {
            setTablets(prev => [...prev, letter]);
        }
    }

    const handleOptionSwitch = event => {
        if (event.target.checked) {
            setOption("simple");
        } else {
            setOption("full");
        }
    }

    const handleExactSwitch = () => {
        setExact(!exact);
    }

    const onSliderChange = e => {
        setMatchLength(e.target.value);
    }

    return (
        <React.Fragment>
            <Grid container className="search-image"></Grid>
            <Container
                className="home-content"
                maxWidth="md"
                sx={{ mt: 0, mb: 2 }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Card sx={{ p: 2, pb: 0, mt: 2 }}>
                            <CardContent>
                                <Grid container
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        mb: 2
                                    }}
                                >
                                    <img src={require("../img/icons/intro.png")} className="intro-image" />
                                    <Typography
                                        sx={{ fontWeight: "bold" }}
                                        variant="h4"
                                    >
                                        &nbsp;Compare
                                    </Typography>
                                    <Typography variant="h4">
                                        &nbsp;Texts
                                    </Typography>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch />}
                                                label="Simplified encoding"
                                                onChange={handleOptionSwitch}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch />}
                                                label="Exact match"
                                                onChange={handleExactSwitch}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography>
                                                    Length
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Slider
                                                    aria-label="Match length"
                                                    defaultValue={6}
                                                    valueLabelDisplay="auto"
                                                    step={1}
                                                    marks
                                                    min={4}
                                                    max={10}
                                                    value={matchLength}
                                                    onChange={onSliderChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <TabletSelection
                                    selectedTablets={tablets}
                                    tablets={TABLETS}
                                    handleClick={checkboxHandler}
                                />

                                <Box textAlign="center" sx={{ mt: 2 }}>
                                    {(valid &&
                                        <Button
                                            variant="gradient"
                                            sx={{ minWidth: "25%" }}
                                            onClick={handleSubmit}
                                        >
                                            search
                                        </Button>) ||
                                        <Button disabled
                                            variant="contained"
                                            sx={{ minWidth: "25%" }}
                                            onClick={handleSubmit}
                                        >
                                            search
                                        </Button>
                                    }
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>

                    {searching &&
                        <Container>
                            <Grid container
                                xs={12}
                                sx={{ mt: 2, justifyContent: "center" }}
                            >
                                <CircularProgress />
                            </Grid>
                        </Container>
                    }

                    {!searching && searched &&
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Typography>
                                <strong>{size}</strong> matches found
                            </Typography>
                        </Grid>
                    }

                    {matches.length > 0 &&
                        <Grid item xs={12}>
                            <CompareList data={matches} />
                        </Grid>
                    }


                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default Compare;
