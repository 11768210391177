import SearchIcon from "@mui/icons-material/Search";

import { TextField, InputAdornment } from "@mui/material";

const SearchInput = props => {
    return (
        <TextField
            size="small"
            autoComplete="off"
            variant="outlined"
            id="outlined-basic"
            label={props.placeholder}
            InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
            }}
            sx={{ minWidth: "100%", mb: 2 }}
            onChange={props.onChange}
        />
    );
}

export default SearchInput;