import { useCallback } from "react";

// use callback = function never gets recreated (avoid never ending loops)
export const useHttpClient = () => {
    const sendRequest = useCallback(async (url, method = 'GET', body = null, headers = {}, signal) => {      
        try {
            const response = await fetch(url, {
                method,
                body,
                headers,
                signal
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.message);
            }
            
            return responseData;
        } catch (err) {
            console.log(err);
            
            throw err;
        }
    }, []);

    return { sendRequest };
};