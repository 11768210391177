import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Container,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

const Home = () => {
    const pages = [
        {
            title: "Glyph stats",
            content: "Explore the frequency and common bigrams of a glyph",
            media: "glyphStats.png",
            link: "glyph",
        },
        {
            title: "Text stats",
            content:
                "Explore glyph and bigram frequencies in one or more texts",
            media: "tabletStats.png",
            link: "tablet",
        },
        {
            title: "Parallels",
            content:
                "Search for exact or close matches of a sequence of glyphs",
            media: "parallels.png",
            link: "parallel",
        },
        {
            title: "Compare",
            content: "Search for matching sequences between two texts",
            media: "compare.png",
            link: "compare",
        },
    ];

    return (
        <React.Fragment>
            {/* HERO */}
            <Grid container className="hero-image">
                <Container
                    className="hero-title"
                    maxWidth="lg"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ maxWidth: { xs: "90%", md: "50%" } }}>
                        <img
                            src={require("../img/icons/title.png")}
                            width="100%"
                        />
                    </Box>

                    <Typography
                        variant="h2"
                        sx={{ color: "white", mb: 3, mt: 1 }}
                    >
                        Tools for advancing the study of{" "}
                        <strong>Easter Island's</strong> writing system
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <ScrollLink
                                to="about"
                                smooth={true}
                                offset={-72}
                                duration={500}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    sx={{
                                        "&:hover": { color: "white" },
                                        width: "100%",
                                    }}
                                >
                                    Read more
                                </Button>
                            </ScrollLink>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <ScrollLink
                                to="tools"
                                smooth={true}
                                offset={-72}
                                duration={500}
                            >
                                <Button
                                    variant="gradient"
                                    sx={{
                                        "&:hover": { color: "white" },
                                        width: "100%",
                                    }}
                                >
                                    Explore
                                </Button>
                            </ScrollLink>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

            {/* CONTENT */}
            <Grid
                container
                minWidth="100vw"
                maxWidth={false}
                sx={{ m: 0, p: 0, backgroundColor: "white" }}
            >
                <Container>
                    {/* ABOUT */}
                    <section id="about">
                        <Grid item xs={12} sx={{ mt: 2, p: 2 }}>
                            <Grid container>
                                <img
                                    src={require("../img/icons/intro.png")}
                                    className="paragraph-image"
                                />
                                <Typography
                                    variant="h4"
                                    sx={{ fontWeight: "bold", flex: 1, ml: 1 }}
                                >
                                    What is rongorongo?
                                </Typography>
                            </Grid>
                            <Box sx={{ mt: 2 }}>
                                <Typography paragraph={true}>
                                    Rongorongo is a unique writing system
                                    developed by the people of Easter Island.
                                    The writing survives in a small corpus of 26
                                    inscribed wooden artefacts. Contrary to some
                                    claims, it remains undeciphered.
                                </Typography>
                                <Typography paragraph={true}>
                                    The majority of scholars accept that
                                    rongorongo functioned as writing, most
                                    likely through a combination of logographic
                                    and syllabic glyphs. The only passage whose
                                    meaning is relatively understood is a lunar
                                    calendar on Text C.
                                </Typography>
                                <Typography paragraph={true}>
                                    If you want to know more, an excellent
                                    source is{" "}
                                    <MuiLink
                                        href="http://kohaumotu.org/"
                                        target="_blank"
                                        sx={{ textDecoration: "none" }}
                                    >
                                        kohaumotu.org
                                    </MuiLink>
                                    . Glyph drawings are those of Barthel (1958)
                                    as available on the old{" "}
                                    <MuiLink
                                        href="http://kohaumotu.org/rongorongo_org/"
                                        target="_blank"
                                        sx={{ textDecoration: "none" }}
                                    >
                                        CEIPP site
                                    </MuiLink>
                                    .
                                </Typography>
                            </Box>
                        </Grid>
                    </section>

                    {/* AVAILABLE TOOLS */}
                    <section id="tools">
                        <Grid item xs={12} sx={{ mt: 2, p: 2, mb: 2 }}>
                            <Grid container>
                                <img
                                    src={require("../img/icons/intro.png")}
                                    className="paragraph-image"
                                />
                                <Typography
                                    variant="h4"
                                    sx={{ fontWeight: "bold", flex: 1, ml: 1 }}
                                >
                                    Available tools
                                </Typography>
                            </Grid>
                            <Box sx={{ mt: 2 }}>
                                <Typography paragraph={true}>
                                    <li>
                                        Explore the frequency of a single glyph
                                        in one or more texts, as well as its
                                        most frequent and most likely
                                        collocations (bigrams). Frequencies are
                                        expressed in percent of all glyphs or
                                        bigrams, while the bigram likelihood is
                                        expressed as the log-likelihood ratio
                                        (Dunning 1993).
                                    </li>
                                </Typography>
                                <Typography paragraph={true}>
                                    <li>
                                        Explore text-wise statistics, showing
                                        the most common glyphs and bigrams for
                                        one or more texts.
                                    </li>
                                </Typography>
                                <Typography paragraph={true}>
                                    <li>
                                        {" "}
                                        Search for occurrences of a sequence of
                                        two or more glyphs. Matches can be exact
                                        or approximate - the latter allowing for
                                        a single replacement or insertion.
                                    </li>
                                </Typography>
                                <Typography paragraph={true}>
                                    <li>
                                        Compare two texts searching for common
                                        sequences of glyphs in both of them.
                                        Please, notice that only a subset of
                                        texts is available - texts of very short
                                        length have been excluded, as has text
                                        I, which is somewhat of an outlier in
                                        terms of its contents.
                                    </li>
                                </Typography>
                            </Box>
                        </Grid>
                    </section>
                </Container>
            </Grid>

            <Container>
                {/* CARDS */}
                <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
                    {pages.map((item) => (
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardActionArea
                                    className="home-card"
                                    component={Link}
                                    to={item.link}
                                    sx={{
                                        minHeight: { xs: "190px", sm: "200px" },
                                        "&:hover": {
                                            // color: "white", background: "linear-gradient(45deg, #42A5F5, #2962FF)"
                                            color: "white",
                                            // background:
                                            //     "linear-gradient(135deg, #654ea3, #eaafc8)",
                                            background: "#0073E6",
                                        },
                                        padding: "12px",
                                    }}
                                >
                                    <CardContent>
                                        <img
                                            src={require(
                                                "../img/icons/" + item.media
                                            )}
                                            height="36"
                                        />
                                        <Typography
                                            variant="h5"
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography>{item.content}</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Grid
                container
                minWidth="100vw"
                maxWidth={false}
                sx={{ m: 0, p: 0, backgroundColor: "white" }}
            >
                <Container>
                    {/* SIMPLIFIED */}
                    <Grid item xs={12} sx={{ mt: 2, p: 2 }}>
                        <Grid container>
                            <img
                                src={require("../img/icons/intro.png")}
                                className="paragraph-image"
                            />
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: "bold", flex: 1, ml: 1 }}
                            >
                                Simplified encoding
                            </Typography>
                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <Typography paragraph={true}>
                                The standard encoding of Rongorongo was
                                developed by Thomas Barthel (1958) and uses a
                                3-digit code for representing ca. 600 glyphs.
                            </Typography>
                            <Typography paragraph={true}>
                                Most rongorongo scholars, however, recognise
                                that Barthel's encoding has inconsistencies.
                                Many glyphs that receive their own code are
                                demonstrably variants of other glyphs or can be
                                decomposed into smaller elements.
                            </Typography>
                            <Typography paragraph={true}>
                                Here, I include a simplified encoding of the
                                corpus. Originally, I followed some of the
                                suggestions of Igor and Konstantin Pozdniakov
                                (1996, 2007) and Paul Horley (2005, 2007). With
                                the recent publication of Horley's {" "}
                                <MuiLink
                                    href="https://rapanuipress.com/producto/rongorongo-2"
                                    target="_blank"
                                    sx={{ textDecoration: "none" }}
                                >
                                    Rongorongo
                                </MuiLink>{" "}
                                (2021), I adopted the encoding proposed in that
                                book. Under that system, for example, the glyphs{" "}
                                <img
                                    src={require("../img/glyphs/304.png")}
                                    height="24"
                                />{" "}
                                304 and{" "}
                                <img
                                    src={require("../img/glyphs/306.png")}
                                    height="24"
                                />{" "}
                                306 in Barthel's system are both encoded as{" "}
                                <img
                                    src={require("../img/glyphs/200.png")}
                                    height="24"
                                />{" "}
                                <img
                                    src={require("../img/glyphs/006.png")}
                                    height="24"
                                />{" "}
                                200 006.
                            </Typography>
                        </Box>
                    </Grid>

                    {/* HOW TO SEARCH */}
                    <Grid item xs={12} sx={{ mt: 2, p: 2, mb: 4 }}>
                        <Grid container>
                            <img
                                src={require("../img/icons/intro.png")}
                                className="paragraph-image"
                            />
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: "bold", flex: 1, ml: 1 }}
                            >
                                How to search?
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                <Typography paragraph={true}>
                                    To search for a particular glyph, just type
                                    the corresponding 3-digit code (with or
                                    without trailing zeros). For example, to
                                    search for{" "}
                                    <img
                                        src={require("../img/glyphs/099.png")}
                                        height="24"
                                    />{" "}
                                    type either 99 or 099. Similarly, to search
                                    for a sequence, type at least two glyphs
                                    separated by spaces. For example, to search
                                    for{" "}
                                    <img
                                        src={require("../img/glyphs/380.png")}
                                        height="24"
                                    />
                                    <img
                                        src={require("../img/glyphs/001.png")}
                                        height="24"
                                    />
                                    <img
                                        src={require("../img/glyphs/003.png")}
                                        height="24"
                                    />{" "}
                                    type either 380 1 3 or 380 001 003.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* FOOTER */}
                    <Grid item xs={12} sx={{ mt: 2, p: 2 }}>
                        <Grid>
                            <Typography sx={{ mb: 1 }}>
                                Built and designed by Jonas Gregorio
                            </Typography>
                            <a
                                href="https://twitter.com/jonasgdes"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <TwitterIcon
                                    style={{ fontSize: 36, color: "black" }}
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/in/jgregoriods/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <LinkedInIcon
                                    style={{ fontSize: 36, color: "black" }}
                                />
                            </a>
                            <a
                                href="https://github.com/jgregoriods"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <GitHubIcon
                                    style={{ fontSize: 36, color: "black" }}
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </React.Fragment>
    );
};

export default Home;
