import React, { useState } from "react";
import {
    Card,
    CardContent,
    Pagination,
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    Typography
} from "@mui/material";

import Glyph from "./glyph";

const GlyphList = props => {
    const numPages = Math.ceil(props.data.length / 5);
    const [currentData, setCurrentData] = useState(props.data.slice(0, 5));

    const handleClick = value => {
        const start = (value - 1) * 5;
        setCurrentData(props.data.slice(start, start + 5));
    }

    return (
        <Card>
            <CardContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ borderBottom: "none" }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    {(props.bigrams && `Bigram`) || `Glyph`}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    Code
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    {((props.percent || !props.bigrams) && `Frequency`) || `Likelihood`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(props.bigrams && (
                            currentData.map(item => (
                                <React.Fragment>
                                <TableRow key={item[0][0] + item[0][1]}>
                                    <TableCell>
                                        <Glyph glyphCode={item[0][0]} glyphSize={32} />
                                        <Glyph glyphCode={item[0][1]} glyphSize={32} />
                                    </TableCell>
                                    <TableCell>
                                        {item[0][0]} {item[0][1]}
                                    </TableCell>
                                    <TableCell>
                                        {(props.percent && (item[1] * 100).toFixed(2) + `%`) || item[1].toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                </React.Fragment>
                            )))) || (
                                currentData.map(item => (
                                    <TableRow key={item[0]}>
                                        <TableCell>
                                            <Glyph glyphCode={item[0]} glyphSize={32} />
                                        </TableCell>
                                        <TableCell>
                                            {item[0]}
                                        </TableCell>
                                        <TableCell>
                                            {(item[1] * 100).toFixed(2) + `%`}
                                        </TableCell>
                                    </TableRow>

                                )))}
                    </TableBody>
                </Table>
                <Pagination
                    size="small"
                    count={numPages}
                    sx={{ mt: 1 }}
                    onChange={(e, value) => handleClick(value)}
                />
            </CardContent>
        </Card>
    );
}

export default GlyphList;