import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import Navbar from "./components/navigation";
import Footer from "./components/footer";
import Home from "./pages/Home";
import GlyphSearch from "./pages/GlyphSearch";
import ParallelSearch from "./pages/ParallelSearch";
import Compare from "./pages/Compare";
import ClassifyGenres from "./pages/Classify";
import { theme } from "./theme";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <div className="body">
                    <Navbar />
                    <ScrollToTop />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/glyph" element={<GlyphSearch tabletMode={false} key={"glyph"} />} />
                        <Route exact path="/tablet" element={<GlyphSearch tabletMode={true} key={"tablet"} />} />
                        <Route exact path="/parallel" element={<ParallelSearch />} />
                        <Route exact path="/compare" element={<Compare />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
