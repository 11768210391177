import {
    createTheme,
    responsiveFontSizes,
    experimental_sx as sx
} from "@mui/material/styles";
import { blue } from "@mui/material/colors";

let theme = createTheme({
    typography: {
        h4: {
            fontSize: "2.5rem"
        },
        h5: {
            fontSize: "2rem"
        },
        h6: {
            fontSize: "1.5rem"
        },
        body1: {
            fontSize: "1.2rem"
        }
    },
    palette: {
        primary: {
            // main: blue["A400"],
            // dark: blue["A700"]
            main: "#0073E6",
            dark: "#0073E6"
        },
        secondary: {
            main: "#ffffff",
            dark: "#ffffff"
        },
        success: {
            main: "#000000",
            dark: "#000000"
        }
    },
    shadows: [
        "none",
        "none",
        ...Array(23).fill("0px 0px 24px rgba(0, 0, 0, 0.08)")
    ],
    components: {
        MuiCard: {
            styleOverrides: {
                root: sx({
                    borderRadius: 4,
                    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.08)"
                })
            }
        },
        MuiButton: {
            styleOverrides: {
                root: sx({
                    fontSize: "1rem",
                    borderRadius: "0.7rem",
                    padding: "8px"
                })
            },
            variants: [
                {
                    props: { variant: "gradient" },
                    style: {
                        // background: "linear-gradient(45deg, #42A5F5, #2962FF)",
                        // background: "linear-gradient(45deg, #654ea3, #eaafc8)",
                        // backgroundColor: blue["A700"],
                        backgroundColor: "#0073E6",
                        color: "white",
                        ":hover": {
                            // background: blue["A700"],
                            // backgroundColor: blue["A700"]
                            background: "#0073E6",
                            backgroundColor: "#0073E6"
                        }
                    }
                }
            ]
        },
        
    }
});

theme = responsiveFontSizes(theme);

export { theme };