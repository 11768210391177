import React from "react";

const Logo = () => {
    return (
        <React.Fragment>
            <strong>Rongo</strong>Script
        </React.Fragment>
    );
}

export default Logo;