import { useState, cloneElement } from "react";
import { Link } from "react-router-dom";

import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useScrollTrigger
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import Logo from "./logo";

const NavbarScroll = props => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 2
    });

    return cloneElement(props.children, {
        elevation: trigger ? 2 : 0,
        style: {
            background: trigger ? "white" : "none",
            transition: trigger ? "0.3s" : "0.0s",
            color: trigger ? "black" : "white"
        }
    });
}

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = event => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 2
    });

    const pages = [
        {
            name: "Glyphs",
            link: "glyph"
        },
        {
            name: "Texts",
            link: "tablet"
        },
        {
            name: "Parallels",
            link: "parallel"
        },
        {
            name: "Compare",
            link: "compare"
        }
    ]

    return (
        <NavbarScroll>
            <AppBar position="fixed" sx={{ bgcolor: "white", color: "black", p: 1 }}>
                <Container maxWidth={false}>
                    <Toolbar disableGutters>
                        <Box
                            component={Link}
                            to="/"
                            sx={{
                                mt: "auto",
                                mb: "auto",
                                mr: 1,
                                filter: trigger ? "none" : "invert()"
                            }}
                        >
                            <img src={require("../img/icons/logo.png")} height={24} />
                        </Box>

                        <Typography
                            component={Link}
                            to="/"
                            variant="h6"
                            sx={{
                                textDecoration: "none",
                                color: trigger ? "black" : "white"
                            }}
                        >
                            <Logo />
                        </Typography>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", md: "flex" },
                                justifyContent: "end"
                            }}
                        >
                            {pages.map(item => (
                                <Button
                                    component={Link} to={item.link}
                                    sx={{
                                        color: trigger ? "black" : "white",
                                        transition: trigger ? "0.3s" : "0.0s",
                                        "&:hover": { color: trigger ? "black" : "white" }
                                    }}
                                >
                                    {item.name}
                                </Button>
                            ))}
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                                justifyContent: "end"
                            }}
                        >
                            <IconButton
                                sx={{ color: "inherit" }}
                                onClick={handleOpenNavMenu}
                            >
                                <MenuIcon fontSize="large" />
                            </IconButton>

                            <Menu
                                anchorEl={anchorElNav}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{ display: { xs: "block", md: "none" } }}
                            >
                                {pages.map(item => (
                                    <MenuItem
                                        component={Link}
                                        to={item.link}
                                        sx={{ "&:hover": { color: "black" } }}
                                        onClick={handleCloseNavMenu}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </NavbarScroll>
    );
}

export default Navbar;
