import React from "react";

import Glyph from "./glyph";

const GlyphString = props => {
    const glyphs = props.string.split(" ");
    return (
        <React.Fragment>
            {glyphs.map(item => (
                <Glyph
                    glyphCode={item}
                    glyphSize={props.glyphSize}
                />
            ))}
        </React.Fragment>
    );
}

export default GlyphString;