const Glyph = props => {
    try {
        return (
            <img src={require("../img/glyphs/" + props.glyphCode.slice(0, 3) + ".png")} height={props.glyphSize} />
        );
    } catch (e) {
        return (
            <img src={require("../img/glyphs/000.png")} height={props.glyphSize} />
        );
    }
}

export default Glyph;