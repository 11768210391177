import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";

import GlyphString from "./glyph_string";

const ParallelCard = props => {
    return (
        <React.Fragment>
            {(props.matches > 0 && (
                <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                    <Grid item

                        display="flex"
                        justifyContent={{ xs: "center", md: "flex-end" }}
                    >
                        <GlyphString string={props.text} glyphSize={48} />
                        <br />
                    </Grid>
                    <Grid item

                        display="flex"
                        justifyContent={{ xs: "center", md: "flex-start" }}
                    >
                        <div>
                            <Typography>
                                {props.matches} {(props.matches > 1 && `matches`) || `match`} found for
                            </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                                {props.text}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            )) || (
                    <div>
                        Not found
                    </div>
                )}
        </React.Fragment>
    );
}

export default ParallelCard;