import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";  // avoid category scale error
import { blue } from "@mui/material/colors";

import { Card, CardContent, Typography } from "@mui/material";

const GlyphChart = props => {
    // props.data.datasets[0].backgroundColor = blue["A400"];
    props.data.datasets[0].backgroundColor = "#0073E6";
    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: { grid: { display: false } },
            y: { grid: { display: false } }
        }
    };
    return (
        <Card>
            <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Glyph count
                </Typography>
                <Bar data={props.data} options={options} />
            </CardContent>
        </Card>
    );
}

export default GlyphChart;