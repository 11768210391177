import React from "react";
import { useState } from "react";
import {
    Card,
    CardContent,
    Divider,
    Grid,
    Pagination,
    Typography
} from "@mui/material";

import GlyphString from "../components/glyph_string";

const CompareList = props => {
    const numPages = Math.ceil(props.data.length / 5);
    const [currentData, setCurrentData] = useState(props.data.slice(0, 5));

    const handleClick = value => {
        const start = (value - 1) * 5;
        setCurrentData(props.data.slice(start, start + 5));
    }

    return (
        <Card sx={{ p: 2, pb: 0 }}>
            <CardContent>
                {currentData.map(item => (
                    <React.Fragment>
                        <Grid container>
                            {Object.keys(item).map(key => (
                                <Grid item xs={12} sm={6} sx={{ mt: 1, mb: 1 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "bold" }}>
                                        {key}
                                    </Typography>
                                    <GlyphString string={item[key]} glyphSize={32} />
                                    <Typography>{item[key]}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                        <Divider />
                    </React.Fragment>
                ))}
                <Pagination
                    size="small"
                    count={numPages}
                    sx={{ mt: 2 }}
                    onChange={(e, value) => handleClick(value)}
                />
            </CardContent>
        </Card>
    );
}

export default CompareList;