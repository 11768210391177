import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

import Glyph from "./glyph";

const GlyphCard = props => {
    return (
        <React.Fragment>
            {(props.data[0][1] > 0 &&
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                >
                    <Grid item sx={{ mt: "auto", mb: "auto" }}>
                        <Glyph glyphCode={props.data[0][0]} glyphSize={48} />
                    </Grid>
                    <Grid item>
                        <div>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Glyph {props.data[0][0]}
                            </Typography>
                            <Typography>
                                Occurs with a frequency of {(props.data[0][1] * 100).toFixed(2)}%
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            ) ||
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                >
                    <Grid item sx={{ mt: "auto", mb: "auto" }}>
                        Not found
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}

export default GlyphCard;